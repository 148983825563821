import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TApplication,
  TApplicationLegalDocs,
  TApplicationStructure,
  TGetApplicationParams,
  TGetTagsParams,
  TTagsResponse,
} from './applications.types';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Application', 'ApplicationStructure', 'ApplicationTags'],
  endpoints: (builder) => ({
    getApplication: builder.query<TApplication, TGetApplicationParams>({
      query: ({ id, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `/rest/application/username-or-clientid-modal/${id}/`,
        params: {
          le: legalEntity,
        },
      }),
      providesTags: ['Application'],
    }),
    getApplicationStructure: builder.query<
      TApplicationStructure,
      TGetApplicationParams
    >({
      query: ({ id, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `/rest/sections/`,
        params: {
          application_id: id,
          legalEntity,
        },
      }),
      providesTags: ['ApplicationStructure'],
    }),

    getApplicationLogs: builder.query<(string | number)[][], { id?: number }>({
      query: ({ id }) => ({
        url: `/rest/application/modal/${id}/logs/`,
      }),
    }),

    getApplicationLegalDocs: builder.query<
      TApplicationLegalDocs[],
      { id?: number }
    >({
      query: ({ id }) => ({
        url: `/rest/application/modal/${id}/legaldocs/?le=All`,
      }),
    }),

    getApplicationOtherLegalDocs: builder.query<
      TApplicationLegalDocs[],
      { id?: number }
    >({
      query: ({ id }) => ({
        url: `/rest/application/modal/${id}/other-legaldocs/?le=All`,
      }),
    }),

    getApplicationLegalDocUrl: builder.query<
      { url: string },
      { docId: number; legalEntity: string }
    >({
      query: ({ docId, legalEntity }) => ({
        url: `/rest/legaldocs/signed/${docId}/download/?le=${legalEntity}`,
      }),
    }),

    getApplicationLegalDocPDFUrl: builder.query<
      { url: string },
      { urlWithId: string }
    >({
      query: ({ urlWithId }) => ({
        url: `${urlWithId}json/?download=true`,
      }),
    }),

    getApplicationLegalDocPDF: builder.query<Blob, { url: string }>({
      query: ({ url }) => ({
        url: `${url}`,
        requestOptions: { responseType: 'blob' },
      }),
    }),

    applicationActions: builder.mutation<
      void,
      { applicationId: number; action: string; successMessage: string }
    >({
      query: ({ applicationId, action }) => ({
        url: `/clientsarea/crm/application/${applicationId}/${action}/`,
        method: 'POST',
      }),
      async onQueryStarted({ successMessage }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: successMessage || 'User successfully transitioned',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    loginAction: builder.query<void, { applicationId: number }>({
      query: ({ applicationId }) => ({
        url: `/clientsarea/crm/sudo/${applicationId}/?noredirect`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Application redirected to login',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    convertToApplication: builder.query<void, { applicationId: number }>({
      query: ({ applicationId }) => ({
        url: `/rest/application/${applicationId}/convert/`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Converted to application',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    sendMessageToClient: builder.mutation<
      void,
      {
        applicationId: number;
        params: { locale: string; message_text: string };
      }
    >({
      query: ({ applicationId, params }) => ({
        url: `/rest/application/${applicationId}/send-message/`,
        method: 'POST',
        data: params,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Message has been sent',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    getApplicationTags: builder.query<TTagsResponse[], TGetTagsParams>({
      query: ({ objectId, sectionName, legalEntity = 'All' }) => ({
        url: `/rest/tag-group/app_related_tagvalues/`,
        params: {
          object_id: objectId,
          name__in: sectionName,
          le: legalEntity,
        },
      }),
      providesTags: ['ApplicationTags'],
    }),
  }),
});

export const {
  useGetApplicationQuery,
  useLazyGetApplicationQuery,
  useGetApplicationStructureQuery,
  useLazyGetApplicationStructureQuery,
  useGetApplicationLogsQuery,
  useGetApplicationLegalDocsQuery,
  useGetApplicationOtherLegalDocsQuery,
  useLazyGetApplicationLegalDocUrlQuery,
  useLazyGetApplicationLegalDocPDFUrlQuery,
  useLazyGetApplicationLegalDocPDFQuery,
  useApplicationActionsMutation,
  useLazyLoginActionQuery,
  useLazyConvertToApplicationQuery,
  useSendMessageToClientMutation,
  useLazyGetApplicationTagsQuery,
} = applicationsApi;
