import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Select } from 'react-ui-kit-exante';

import {
  useApplicationActionsMutation,
  useLazyLoginActionQuery,
  useLazyConvertToApplicationQuery,
  useSendMessageToClientMutation,
} from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { PATHS } from '~/router';

import { EApplicationActionsType } from '../../ApplicationEntry.constants';
import { StyledCommentInput } from '../../ApplicationEntry.styled';

import {
  StyledApplicationFilter,
  StyledApplicationFilters,
  StyledApplicationWrapper,
} from './Header.styled';

export const Header = () => {
  const navigate = useNavigate();

  const {
    application,
    isLoadingApplication,
    activeLegalEntity,
    setActiveLegalEntity,
  } = useContext(ApplicationContext);

  const [applicationActions, stateApplicationActions] =
    useApplicationActionsMutation();
  const [loginAction, stateLoginAction] = useLazyLoginActionQuery();
  const [convertAction, stateConvertAction] =
    useLazyConvertToApplicationQuery();
  const [sendMessage] = useSendMessageToClientMutation();

  const [activeConfirm, setActiveConfirm] = useState('');
  const [blockBtnAnchorEl, setBlockBtnAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [messageToClient, setMessageToClient] = useState('');

  const onCloseEntry = () => navigate(`${PATHS.USERS}`);

  const applicationId = application?.application?.id || 0;
  const isLoadingActions =
    isLoadingApplication ||
    stateApplicationActions.isLoading ||
    stateLoginAction.isLoading ||
    stateConvertAction.isLoading;

  const approveBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Approve"
      onConfirm={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.Approve,
          successMessage: 'Application approved',
        })
      }
      title="Please, confirm"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="CircleCheckIcon"
        iconSize={32}
        iconColor="action"
        title="Approve"
        label="Approve"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const rejectBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Unapprove"
      onConfirm={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.Unapprove,
          successMessage: 'Application unapproved',
        })
      }
      title="Please, confirm"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="CircleCloseIcon"
        iconColor="radical"
        label="Unapprove"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const sendAppToOnboardingBtn = (
    <IconButton
      iconSize={32}
      iconName="WithdrawFromAccountIcon"
      label="Send to onboarding"
      iconColor="ghost"
      onClick={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.ToOnboarding,
          successMessage: 'Application sent to onboarding',
        })
      }
      disabled={isLoadingActions}
    />
  );

  const sendAppToPendingBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Send to pending"
      onConfirm={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.ToPending,
          successMessage: 'Application sent to pending',
        })
      }
      title="Pending reason"
      content={
        <StyledCommentInput
          className="CommentInput"
          label="Comment"
          onChange={() => {}}
          value=""
          multiline
        />
      }
    >
      <IconButton
        iconSize={32}
        iconName="CircleClockIcon"
        label="Send to pending"
        iconColor="ghost"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const sendAppToRegistrantBtn = (
    <IconButton
      iconSize={32}
      iconName="WithdrawFromAccountIcon"
      label="Send to registrant"
      iconColor="ghost"
      onClick={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.FromPendingToRegistrant,
          successMessage: 'Application sent to registrant',
        })
      }
      disabled={isLoadingActions}
    />
  );

  const deleteBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Delete"
      onConfirm={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.Delete,
          successMessage: 'Application deleted',
        })
      }
      title="Do you want to delete the user?"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="DeleteIcon"
        iconColor="ghost"
        label="Delete"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const loginBtn = (
    <IconButton
      iconSize={32}
      iconName="LoginIcon"
      label="Login"
      iconColor="ghost"
      onClick={() =>
        loginAction({
          applicationId,
        })
      }
      disabled={isLoadingActions}
    />
  );

  const renderBlockConfirm = (
    <Select
      sx={{ width: 300 }}
      options={[]}
      onChange={() => {}}
      value=""
      placeholder="Closure reason"
      label="Closure reason"
    />
  );

  const openBlockConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setActiveConfirm('block');
    setBlockBtnAnchorEl(event.currentTarget);
  };

  const closeBlockConfirm = () => {
    setActiveConfirm('');
    setBlockBtnAnchorEl(null);
  };

  const blockBtn = (
    <ActionWithConfirmationWithSelect
      key="onRejectBtn"
      open={activeConfirm === 'block'}
      anchorEl={blockBtnAnchorEl}
      onClose={closeBlockConfirm}
      title="Close Relationshipt"
      content={renderBlockConfirm}
      confirmText="Close Relationship"
      handleConfirm={() => {
        applicationActions({
          applicationId,
          action: EApplicationActionsType.Block,
          successMessage: 'Application blocked',
        });

        setActiveConfirm('');
      }}
      handleClose={closeBlockConfirm}
      disabledConfirm={isLoadingActions}
    >
      <IconButton
        iconName="BlockAccountIcon"
        iconColor="radical"
        label="Close Relationship"
        iconSize={32}
        disabled={isLoadingActions}
        onClick={openBlockConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const unblockBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Unblock"
      onConfirm={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.Unblock,
          successMessage: 'Application unblocked',
        })
      }
      content="Do you want to unblock and restore the permissions to the site and ATP and restore the demo access?"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="ReadyForActivationIcon"
        iconColor="ghost"
        label="Unblock"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const convertBtn = (
    <IconButton
      iconSize={32}
      iconName="ArrowsClockwiseIcon"
      label="Convert to application"
      iconColor="action"
      onClick={() =>
        convertAction({
          applicationId,
        })
      }
      disabled={isLoadingActions}
    />
  );

  const sendToQualifiedBtn = (
    <IconButton
      iconSize={32}
      iconName="ArrowsClockwiseIcon"
      label="Send to qualified"
      iconColor="action"
      onClick={() =>
        applicationActions({
          applicationId,
          action: EApplicationActionsType.FromPendingToQualified,
          successMessage: 'Application sent to qualified',
        })
      }
      disabled={isLoadingActions}
    />
  );

  const sendMessageBtn = (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Send"
      onConfirm={() => {
        sendMessage({
          applicationId,
          params: { locale: 'en', message_text: messageToClient },
        });
      }}
      title="Send a message"
      content={
        <StyledCommentInput
          className="CommentInput"
          label="Comment"
          onChange={(e) => setMessageToClient(e.target.value)}
          value={messageToClient}
          multiline
        />
      }
    >
      <IconButton
        iconSize={32}
        iconName="SendMessageIcon"
        label="Send a message"
        iconColor="ghost"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const showActions = true;

  const actions = [
    showActions ? approveBtn : null,
    showActions ? rejectBtn : null,
    showActions ? sendAppToPendingBtn : null,
    showActions ? sendAppToOnboardingBtn : null,
    showActions ? sendAppToRegistrantBtn : null,
    showActions ? convertBtn : null,
    showActions ? sendToQualifiedBtn : null,
    showActions ? sendMessageBtn : null,
    showActions ? deleteBtn : null,
    showActions ? loginBtn : null,
    showActions ? unblockBtn : null,
    showActions ? blockBtn : null,
  ].filter(Boolean);

  const title = (
    <StyledApplicationWrapper className="ApplicationWrapper">
      {application?.full_name || application?.application?.username}
      <StyledApplicationFilters className="ApplicationFilters">
        {application?.application?.legal_entities.map((item) => {
          return (
            <StyledApplicationFilter
              className="ApplicationFilter"
              key={item}
              isActive={activeLegalEntity === item}
              onClick={() =>
                setActiveLegalEntity(activeLegalEntity === item ? '' : item)
              }
            >
              {item}
            </StyledApplicationFilter>
          );
        })}
      </StyledApplicationFilters>
    </StyledApplicationWrapper>
  );

  return (
    <EntryScreenHeader title={title} onClose={onCloseEntry} actions={actions} />
  );
};
