import { formatDateTime } from '~/utils/dates/formatDate';

import { TGetInfoWithdrawalDetailsFields } from './TWithdrawalForm.types';
import { StyledWithdrawalFormViewLink } from './WithdrawalForm.styled';

export const getInfoWithdrawalDetailsFields = ({
  username,
  accountOwner,
  crmLink,
  accountOwnerCrmLink,
}: TGetInfoWithdrawalDetailsFields) => {
  return [
    {
      key: 'username',
      label: 'Client',
      node: (
        <StyledWithdrawalFormViewLink
          href={crmLink}
          target="_blank"
          rel="noreferrer"
          className="WithdrawalFormViewLink"
        >
          {username}
        </StyledWithdrawalFormViewLink>
      ),
    },
    {
      key: 'client_name',
      label: 'Requester name',
    },
    {
      key: 'account_owner',
      label: 'Withdrawal account owner',
      node: (
        <StyledWithdrawalFormViewLink
          href={accountOwnerCrmLink}
          target="_blank"
          rel="noreferrer"
          className="WithdrawalFormViewLink"
        >
          {accountOwner}
        </StyledWithdrawalFormViewLink>
      ),
    },
    {
      key: 'account_owner_name',
      label: 'Withdrawal account owner name',
    },
  ];
};

export const getWithdrawalDetailsFields = (ipCountry: string) => {
  return [
    {
      key: 'account',
      label: 'Account',
    },
    {
      key: 'legal_entity',
      label: 'Legal entity',
    },
    {
      key: 'deposit_group',
      label: 'Deposit group',
    },
    {
      key: 'assigned_to',
      label: 'Manager',
    },
    {
      key: 'ip',
      label: 'IP / Country',
      node: <div>{ipCountry}</div>,
    },
    {
      key: 'confirm_type',
      label: 'Confirm type',
    },
    {
      key: 'changed',
      label: 'Created',
      formatter: formatDateTime,
    },
    {
      key: 'changed',
      label: 'Changed',
      formatter: formatDateTime,
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: '?',
      label: 'Source',
    },
    {
      key: 'account_nav',
      label: 'Account NAV',
    },
    {
      key: 'total_nav',
      label: 'Total Nav',
    },
  ];
};
